import React from "react";
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Translate } from "../../i18n/Translate";
import { colors } from "../../ui/colors";

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.red,
      contrastText: "white",
    },
  },
});

export const RedButton = ({ text, ...props }) => {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <Button color="primary" {...props}>
        <Translate text={text} />
      </Button>
    </MuiThemeProvider>
  );
};
