import React from "react";
import { checkPersonalCode, parsePersonalCode } from "../utils/personalCode";
import { PersonInfoDefault } from "./default/PersonInfoDefault";
import { PersonInfoExternal } from "./external/PersonInfoExternal";
import { procedures } from "./procedure";

export const PersonInfo = ({ pii }) => {
  let procedure = null;
  let personInfo = null;

  if (checkPersonalCode(pii)) {
    const personalCodeArray = parsePersonalCode(pii);
    [procedure, ...personInfo] = personalCodeArray;
  } else {
    procedure = procedures.EXTERNAL;
  }

  switch (procedure) {
    case procedures.DEFAULT:
      return <PersonInfoDefault idNumber={personInfo[0]} />;
    case procedures.EXTERNAL:
      return <PersonInfoExternal pii={pii} />;
    default:
      return null;
  }
};
