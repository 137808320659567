import React from "react";
import { FormattedDate } from "react-intl";

export const IntlDate = ({ date }) => (
  <FormattedDate
    value={date}
    timeZone="UTC"
    day="2-digit"
    month="2-digit"
    year="numeric"
  />
);
