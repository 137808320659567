import React, { useEffect, useState } from "react";
import { IntlProvider as ReactIntlProvider } from "react-intl";
import en from "./locales/en.json";

const supportedLanguages = ["de", "en"];
const language = navigator.language.split(/[-_]/)[0];

export const IntlProvider = ({ children }) => {
  const [messages, setMessages] = useState(en);

  useEffect(() => {
    if (supportedLanguages.includes(language)) {
      import(`./locales/${language}.json`).then((data) =>
        setMessages(data.default)
      );
    }
  }, []);

  return (
    <ReactIntlProvider messages={messages} locale={language} defaultLocale="en">
      {children}
    </ReactIntlProvider>
  );
};
