import { SEPARATOR } from "../constants";
import { procedures } from "../procedure/procedure";

export const parsePersonalCode = (personalCode) => {
  if (personalCode.includes(SEPARATOR)) {
    const personalCodeArray = personalCode.split(SEPARATOR);
    const procedureCode = personalCodeArray[0];
    switch (procedureCode) {
      case procedures.DEFAULT:
        return personalCodeArray;
      default:
        break;
    }
  }
  return ["", ""];
};

export const checkPersonalCode = (personalCode) => {
  if (
    personalCode &&
    typeof personalCode !== "object" &&
    personalCode.includes(SEPARATOR) &&
    personalCode.split(SEPARATOR).length === 3
  ) {
    return true;
  }
  return false;
};
