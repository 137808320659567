import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Translate } from "../../i18n/Translate";
import styled from "styled-components";

const StyledSnackbar = styled(Snackbar)`
  bottom: 72px;
`;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const Notification = ({ text, isOpen, setIsOpen, type = "success" }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIsOpen(false);
  };

  return (
    <StyledSnackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type}>
        <Translate text={text} />
      </Alert>
    </StyledSnackbar>
  );
};
