import React from "react";
import { CfpCheckInvalid } from "./CfpCheckInvalid";
import { CfpCheckValid } from "./CfpCheckValid";

export const CfpCheckResult = ({ cfp }) => (
  <div>
    {cfp.valid ? (
      <CfpCheckValid pii={cfp.pii} />
    ) : (
      <CfpCheckInvalid pii={cfp.pii} />
    )}
  </div>
);
