import React from "react";
import styled from "styled-components";
import { Translate } from "../i18n/Translate";
import Button from "@material-ui/core/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRightRounded";
import { Link } from "react-router-dom";
import { RedButton } from "../components/buttons/RedButton";

const Layout = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.p`
  padding-bottom: 16px;
  text-align: center;
`;

const InfoButton = styled(Button)`
  margin-top: 8px;
`;

export const Cfp = () => (
  <Layout>
    <Text>
      <Translate text="cfp.info" />
    </Text>
    <RedButton
      component={Link}
      to="/cfp/check"
      variant="contained"
      text="cfp.action.check"
      endIcon={<ChevronRightIcon />}
    />
    <InfoButton
      component="a"
      href="https://cfp.c19t.org"
      rel="noopener noreferrer"
      target="_blank"
      variant="text"
    >
      <Translate text="cfp.action.show_more" />
    </InfoButton>
  </Layout>
);
