import React from "react";
import { ThemeProvider as ScThemeProvider } from "styled-components";
import {
  createMuiTheme,
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import { colors } from "./colors";
import { GlobalStyle } from "./GlobalStyle";

const theme = {
  main: colors.main,
  text: colors.text,
  red: colors.red,
  orange: colors.orange,
  mainLight: colors.mainLight,
};

const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.main,
    },
    secondary: {
      main: colors.text,
    },
  },
  overrides: {
    MuiStepper: {
      root: {
        backgroundColor: "transparent",
      },
    },
    MuiStepIcon: {
      root: {
        color: "rgba(0, 0, 0, 0.10)",
      },
      active: {
        "& $text": {
          fill: "white",
        },
      },
    },
    MuiStepConnector: {
      lineVertical: {
        minHeight: "8px",
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "1em",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        minWidth: "unset",
      },
    },
  },
});

export const ThemeProvider = ({ children }) => (
  <StylesProvider injectFirst>
    <ScThemeProvider theme={theme}>
      <GlobalStyle />
      <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
    </ScThemeProvider>
  </StylesProvider>
);
