import React from "react";
import { Fragment } from "react";
import styled from "styled-components";
import { Translate } from "../../i18n/Translate";
import { BarcodeScanner } from "../../components/BarcodeScanner";
import { colors } from "../../ui/colors";

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const InfoBox = styled.div`
  background-color: ${colors.mainLight};
  padding: 16px;
  max-width: 300px;
  margin-top: 16px;
  border-radius: 6px;
  font-weight: 600;
`;

export const CfpCheck = ({ onScanSuccess }) => (
  <Fragment>
    <BarcodeScanner onScanSuccess={onScanSuccess} />
    <Container>
      <InfoBox>
        <Translate text="cfp.scan.infobox" />
      </InfoBox>
    </Container>
  </Fragment>
);
