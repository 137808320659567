import React from "react";
import styled from "styled-components";
import { Translate } from "../../../i18n/Translate";
import { colors } from "../../../ui/colors";

const Layout = styled.div`
  width: 100%;
`;

const PersonalDataContainer = styled.div`
  background-color: white;
  margin-top: 4px;
  padding: 8px 16px;
`;

const Text = styled.p`
  font-size: 1.1rem;
  margin: 0px;
`;

const Label = styled.span`
  font-size: 0.8rem;
  color: ${colors.textMid};
`;

const ContainerLabel = styled.span`
  font-size: 0.9rem;
  color: ${colors.text};
  font-weight: 600;
  padding-left: 2px;
`;

export const PersonInfoDefault = ({ idNumber }) => (
  <Layout>
    <ContainerLabel>
      <Translate text="cfp.personal_data" />
    </ContainerLabel>
    <PersonalDataContainer>
      <Text>{idNumber}</Text>
      <Label>
        <Translate text="cfp.procedure.default.id_number" />
      </Label>
    </PersonalDataContainer>
  </Layout>
);
