import React from "react";
import QrReader from "react-qr-reader";
import styled from "styled-components";
import { rgba } from "polished";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BarcodeWrapper = styled.div`
  width: 100%;
  height: auto;
  max-width: 300px;

  section,
  video,
  div {
    border: 1px solid transparent;
    border-radius: 6px;
  }

  section > div {
    border: 50px solid ${(props) => rgba(props.variantColor, 0.4)} !important;
    box-shadow: rgba(255, 0, 0, 0.5) 0px 0px 0px 2px inset !important;
  }
`;

export const BarcodeScanner = ({
  onScanSuccess,
  onScanError,
  variantColor = "black",
}) => {
  function onDataReceived(data) {
    if (data) {
      onScanSuccess(data);
    }
  }

  return (
    <Wrapper>
      <BarcodeWrapper variantColor={variantColor}>
        <QrReader
          delay={300}
          onError={onScanError}
          onScan={onDataReceived}
          style={{ width: "100%" }}
          showViewFinder={true}
        />
      </BarcodeWrapper>
    </Wrapper>
  );
};
