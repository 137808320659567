import { useCallback } from "react";

const CONTENT_TYPE_APPLICATION_JSON = "application/json";

const API_VERSION = "2021-03-31";
const APP_VERSION = `web ${process.env.REACT_APP_VERSION}`;

export const getBaseUrl = () => {
  const env = process.env.REACT_APP_KEYPER_ENV
    ? process.env.REACT_APP_KEYPER_ENV.toLowerCase()
    : null;

  switch (env) {
    case "develop":
      return "https://api.dev.c19t.net/v1";
    case "staging":
      return "https://api.sta.c19t.net/v1";
    case "sandbox":
      return "https://api.sbx.c19t.net/v1";
    case "production":
      return "https://api.c19t.net/v1";
    default:
      return "http://localhost:63584";
  }
};

export const DefaultHeaders = {
  "Content-Type": CONTENT_TYPE_APPLICATION_JSON,
  "Api-Version": API_VERSION,
  "App-Version": APP_VERSION,
};

export const useApi = () => {
  let headers = DefaultHeaders;

  function get(url, _headers = headers) {
    return getApi(url, _headers);
  }

  function post(url, body, _headers = headers) {
    return postApi(url, body, _headers);
  }

  function put(url, body, _headers = headers) {
    return putApi(url, body, _headers);
  }

  function del(url, _headers = headers) {
    return deleteApi(url, _headers);
  }

  return {
    post: useCallback(post, [headers]),
    get: useCallback(get, [headers]),
    put: useCallback(put, [headers]),
    del: useCallback(del, [headers]),
  };
};

const postApi = (url, body, headers) => {
  const requestOptions = {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      return response.json().then((json) => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      });
    }
    return null;
  });
};

const putApi = (url, body, headers) => {
  const requestOptions = {
    method: "PUT",
    headers,
    body: JSON.stringify(body),
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      throw new Error("Request failed");
    }
    return null;
  });
};

const getApi = (url, headers) => {
  const requestOptions = {
    method: "GET",
    headers,
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      throw new Error("Request failed");
    }
    return null;
  });
};

const deleteApi = (url, headers) => {
  const requestOptions = {
    method: "DELETE",
    headers,
  };

  return fetch(`${getBaseUrl()}/${url}`, requestOptions).then((response) => {
    if (response.ok) {
      // Check if response has body
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes(CONTENT_TYPE_APPLICATION_JSON)) {
        return response.json();
      }
    } else {
      throw new Error("Request failed");
    }
    return null;
  });
};
