import React, { useContext, useState } from "react";
import { CfpCheck } from "./CfpCheck";
import { Keccak } from "sha3";
import { checkPersonalCode } from "../utils/personalCode";
import { useApi } from "../../utils/api";
import { CenterSpinner } from "../../components/CenterSpinner";
import { CfpCheckResult } from "./CfpCheckResult";
import { NotificationContext } from "../../components/notification/NotificationContext";
import { ERROR_NOTIFICATION } from "../../components/notification/notificationType";

const UiStates = {
  SCAN: 0,
  PENDING: 1,
  CFP_CHECKED: 2,
};

export const CfpCheckContainer = () => {
  const { get, post } = useApi();
  const { showNotification } = useContext(NotificationContext);
  const [uiState, setUiState] = useState(UiStates.SCAN);
  const [cfp, setCfp] = useState({ valid: false });

  async function onScanSuccess(data) {
    setUiState(UiStates.PENDING);

    try {
      if (checkPersonalCode(data)) {
        const hash = new Keccak(256).update(data);
        const personHash = `0x${hash.digest("hex")}`;

        const response = await get(`cfp/check/${personHash}`);
        setCfp({ ...response, pii: data });
      } else {
        const response = await post(`cfp/external/check`, { value: data });
        setCfp({ ...response });
      }

      setUiState(UiStates.CFP_CHECKED);
    } catch (error) {
      showNotification(ERROR_NOTIFICATION, "cfp.check.error");
      setUiState(UiStates.SCAN);
    }
  }
  return (
    <div>
      {uiState === UiStates.SCAN ? (
        <CfpCheck
          onScanSuccess={onScanSuccess}
          onError={() => {
            // TODO
          }}
        />
      ) : null}
      {uiState === UiStates.PENDING ? <CenterSpinner /> : null}
      {uiState === UiStates.CFP_CHECKED ? <CfpCheckResult cfp={cfp} /> : null}
    </div>
  );
};
