import React from "react";
import styled from "styled-components";
import { Spinner } from "./Spinner";

const Layout = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px;
`;

export const CenterSpinner = ({ className }) => (
  <Layout className={className}>
    <Spinner />
  </Layout>
);
