import React from "react";
import styled from "styled-components";
import { AnimatedCheckIcon } from "../../components/icons/AnimatedCheckIcon";
import Button from "@material-ui/core/Button";
import ExpandIcon from "@material-ui/icons/ExpandMoreRounded";
import { PersonInfo } from "../procedure/PersonInfo";
import { Translate } from "../../i18n/Translate";
import { Link } from "react-router-dom";
import { RedButton } from "../../components/buttons/RedButton";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
`;

const Title = styled.p`
  font-size: 1.1em;
  font-weight: 600;
`;

const InfoText = styled.p`
  font-size: 0.9em;
`;

const ResetButton = styled(RedButton)`
  margin-top: 32px;
`;

export const CfpCheckValid = ({ pii }) => {
  return (
    <Layout>
      <AnimatedCheckIcon />
      <Title>
        <Translate text="cfp.valid.title" />
      </Title>
      <PersonInfo pii={pii}></PersonInfo>
      <InfoText>
        <Translate text="cfp.valid.info" />
      </InfoText>
      <Button variant="text" endIcon={<ExpandIcon />}>
        <Translate text="cfp.action.show_details" />
      </Button>
      <ResetButton
        variant="outlined"
        text="action.restart"
        component={Link}
        to="/cfp"
      />
    </Layout>
  );
};
