import React, { Fragment } from "react";
import styled from "styled-components";
import { IntlDate } from "../../../i18n/IntlDate";
import { Translate } from "../../../i18n/Translate";
import { colors } from "../../../ui/colors";

const Layout = styled.div`
  width: 100%;
`;

const PersonalDataContainer = styled.div`
  background-color: white;
  margin-top: 4px;
  padding: 8px 16px;
`;

const Text = styled.p`
  font-size: 1.1rem;
  margin: 0px;
`;

const Label = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: ${colors.textMid};
  margin-bottom: 8px;
`;

const ContainerLabel = styled.span`
  font-size: 0.9rem;
  color: ${colors.text};
  font-weight: 600;
  padding-left: 2px;
`;

export const PersonInfoExternal = ({ pii = {} }) => (
  <Layout>
    <ContainerLabel>
      <Translate text="cfp.personal_data" />
    </ContainerLabel>
    <PersonalDataContainer>
      {pii.name ? (
        <Fragment>
          <Text>{pii.name}</Text>
          <Label>
            <Translate text="cfp.procedure.external.name" />
          </Label>
        </Fragment>
      ) : null}
      {pii.date_of_birth ? (
        <Fragment>
          <Text>
            <IntlDate date={pii.date_of_birth} />
          </Text>
          <Label>
            <Translate text="cfp.procedure.external.date_of_birth" />
          </Label>
        </Fragment>
      ) : null}
      {pii.year_of_birth ? (
        <Fragment>
          <Text>{pii.year_of_birth}</Text>
          <Label>
            <Translate text="cfp.procedure.external.year_of_birth" />
          </Label>
        </Fragment>
      ) : null}
    </PersonalDataContainer>
  </Layout>
);
