import { AppProviders } from "./AppProviders";
import { CfpContainer } from "./cfp/CfpContainer";

const App = () => (
  <AppProviders>
    <CfpContainer />
  </AppProviders>
);

export default App;
