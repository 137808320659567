import React from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { Route, Switch } from "react-router-dom";
import { CfpCheckContainer } from "./check/CfpCheckContainer";
import { Cfp } from "./Cfp";

const Layout = styled.section`
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CfpLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 8px;
  width: 100%;
`;

const CfpLogo = styled.img`
  max-width: 200px;
`;

const Content = styled.div`
  width: 100%;
  padding-top: 16px;
`;

export const CfpContainer = () => {
  const { formatMessage } = useIntl();

  return (
    <Layout>
      <CfpLogoContainer>
        <CfpLogo
          src={process.env.PUBLIC_URL + "/cfplogo.svg"}
          alt={formatMessage({ id: "alt.cfp_logo" })}
        />
      </CfpLogoContainer>
      <Content>
        <Switch>
          <Route component={CfpCheckContainer} path="/cfp/check" />
          <Route component={Cfp} path="/" />
        </Switch>
      </Content>
    </Layout>
  );
};
