import React from "react";
import { NotificationProvider } from "./components/notification/NotificationProvider";
import { BrowserRouter as Router } from "react-router-dom";

import { IntlProvider } from "./i18n/IntlProvider";
import { ThemeProvider } from "./ui/ThemeProvider";

export const AppProviders = ({ children }) => (
  <ThemeProvider>
    <IntlProvider>
      <NotificationProvider>
        <Router>{children}</Router>
      </NotificationProvider>
    </IntlProvider>
  </ThemeProvider>
);
