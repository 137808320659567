import React, { Fragment, useState } from "react";
import { Notification } from "./Notification";
import { NotificationContext } from "./NotificationContext";

export const NotificationProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState(null);
  const [type, setType] = useState("success");

  function showNotification(type = "success", _text) {
    setType(type);
    setText(_text);
    setShow(true);
  }

  return (
    <Fragment>
      <NotificationContext.Provider value={{ showNotification }}>
        {children}
      </NotificationContext.Provider>
      <Notification isOpen={show} setIsOpen={setShow} text={text} type={type} />
    </Fragment>
  );
};
